
const reportSettingApi = {
  // 留资组件列表
  retentionComponentList: `${ZhbHost}/api/marketing/wecom/component/list/get`,

  // 留资组件新建、编辑
  retentionComponentEdit: `${ZhbHost}/api/marketing/wecom/component/edit`,
  // 留资组件删除
  retentionComponentDelete: `${ZhbHost}/api/marketing/wecom/component/delete`,
  // 查询extId的traceId 留资情况
  retentionComponentTraceId: `${ZhbHost}/api/marketing/wecom/customer/extraInfo/get`,
};

export { reportSettingApi };
